import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import store from '@/store/index';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_PROJECT_ID + ".firebaseapp.com",
    databaseURL: "https://" + process.env.VUE_APP_FIREBASE_PROJECT_ID + ".firebaseio.com",
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: "gs://" + process.env.VUE_APP_FIREBASE_PROJECT_ID + ".appspot.com",
    messagingSenderId: "773589326241",
    appId: "1:773589326241:web:8369e761abc154896cd3e0",
    measurementId: "G-WV82VVLJW6"
  };

firebase.initializeApp(firebaseConfig)

firebase.auth().onAuthStateChanged(user => {
    if(user) {
        store.commit('checkLoggedIn', true)
    } else {
        store.commit('checkLoggedIn', false)
    }
  });


export const auth = firebase.auth()
export const fireStorage = firebase.storage()